<template>
  <form @submit.prevent="handleFormSubmission()" novalidate>
    <h2>Sign Up</h2>

    <p v-if="error.usernameTooShort">Username must be at least 3 characters long.</p>
    <p v-if="error.usernameInUse">Username is already taken.</p>
    <input type="text" placeholder="Username" v-model="username" autocomplete="off">

    <p v-if="error.emailInUse">This email is already added to an account.</p>
    <p v-if="error.emailBadlyFormatted">This email is badly formatted.</p>
    <input type="email" placeholder="Email" v-model="email" autocomplete="off">

    <p v-if="error.passwordTooShort">Password must be at least 6 characters long.</p>
    <input type="password" placeholder="Password" v-model="password" autocomplete="off">

    <button class="primary large" type="submit">Sign Up</button>
    <router-link :to="{ name: 'Login' }">Login</router-link>
  </form>
</template>

<script>
  import { getFirestore,  getDocs, collection, query, where } from 'firebase/firestore';
  import { mapActions } from 'vuex'

  export default {
    name: 'Signup',
    data: () => ({
      username: '',
      email: '',
      password: '',
      error: {
        usernameInUse: false,
        emailInUse: false,
        emailBadlyFormatted: false,
        passwordTooShort: false,
        usernameTooShort: false
      }
    }),
    methods: {
      ...mapActions('user', ['signUp']),

      async handleFormSubmission() {
        // Check if username is available
        await this.checkIfUsernameExists()
        // Check is account already exists
        await this.checkIfEmailExists()
        // Verify email format
        await this.checkEmailFormat()
        // Verify password length
        await this.checkPasswordLength()
        // Verify username length
        await this.checkUsernameLength()
        // If conditions are met, submit form
        if (Object.values(this.error).every(val => val === false)) {
          // Sign up new user
          await this.signUp({username: this.username, email: this.email, password: this.password})
          // Remove focus on inputs
          Array.from(document.querySelectorAll('input')).forEach(el => el.blur())
          // Reset state
          this.username = ''
          this.email = ''
          this.password = ''
        }
      },

      async checkIfUsernameExists() {
        // Remove whitespace and spaces from username
        let cleanUsername = this.username.replace(/\s+/g, '')
        // Query database for matching username
        let username = await getDocs(query(collection(getFirestore(), 'Users'), where('username', '==', cleanUsername)))
        // Manage username error
        !username.empty ? this.error.usernameInUse = true : this.error.usernameInUse = false
      },

      async checkIfEmailExists() {
        // Remove whitespace and spaces from email
        let cleanEmail = this.email.replace(/\s+/g, '')
        // Query database for matching username
        let email = await getDocs(query(collection(getFirestore(), 'Users'), where('email', '==', cleanEmail)))
        // Manage username error
        !email.empty ? this.error.emailInUse = true : this.error.emailInUse = false
      },

      checkEmailFormat() {
        // Remove whitespace and spaces from email
        let cleanEmail = this.email.replace(/\s+/g, '')
        // Verify email is formatted correctly
        !cleanEmail.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        ? this.error.emailBadlyFormatted = true : this.error.emailBadlyFormatted = false
      },

      checkPasswordLength() {
        // Remove whitespace and spaces from password
        let cleanPassword = this.password.replace(/\s+/g, '')
        // Verify password is at least 6 characters long
        cleanPassword.length < 6 ? this.error.passwordTooShort = true : this.error.passwordTooShort = false
      },

      checkUsernameLength() {
        // Remove whitespace and spaces from username
        let cleanUsername = this.username.replace(/\s+/g, '')
        // Verify username is at least 3 characters long
        cleanUsername.length < 3 ? this.error.usernameTooShort = true : this.error.usernameTooShort = false
      }
    },
    beforeUnmount() {
      this.$store.commit('user/SET_ALERT')
    }
  }
</script>
